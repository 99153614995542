import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions, Effect } from '@ngrx/effects';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import {
    EnumUserActions,
    SetNotificationCounter,
    SetNotification,
    SetAlert,
    SetFavorite,
    SetSinalization,
    SetUser,
    GetNotification,
    GetAlert,
    GetFavorite,
    GetSinalization,
    UserLogin
} from '@store/actions/user.action';
import { NotificationService } from '@services/notification.service';
import * as moment from 'moment';
import { AlertService } from '@services/alert.service';
import { FavoriteService } from '@services/favorite.service';
import { SinalizationService } from '@services/sinalization.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { FillMarkets } from '@store/actions/market.action';
import { ApiResponse } from '@models/api-response';
import { Markets } from '@models/markets';
import { Sinalization } from '@models/sinalization';
import { Alert } from '@models/alerts';

@Injectable()
export class UserEffects {

    loadNotificationsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.GetNotification),
            switchMap(() => this.notificationService.getNotifications()),
            switchMap((response) => {
                const notificationsResponse = response.data && response.data !== null ? response.data : [];

                const notificationsCounter = response.count ? response.count : 0;

                return of(
                    new SetNotificationCounter(notificationsCounter),
                    new SetNotification(notificationsResponse)
                );
            }),
            catchError(() => of({ type: '[API] Get Notifications Loaded Error' }))
        )
    );

    loadAlertsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.GetAlert),
            switchMap(() => this.alertService.getAlerts()),
            withLatestFrom(this.store),
            switchMap(([response, store]: [ApiResponse, AppState]) => {
                const alertsResponse = response.data && response.data !== null ? response.data : [];
                this.filterDate(alertsResponse);

                const markets = this.getMarketsBySlug(store.markets.list);
                const alertList: Alert[] = [];
                alertsResponse.forEach((alert: Alert) => {
                    const market = markets[alert.mns];
                    if (market) {
                        alert.market = {...market};
                        const marketName = market.nm.split(' ');
                        alert.market.ni = marketName.slice(1).join(' ');
                        alertList.push(alert);
                    }
                });

                return of(
                    new SetAlert(alertList),
                    new FillMarkets()
                );
            }),
            catchError(() => of({ type: '[API] Get Notifications Loaded Error' }))
        )
    );

    loadFavoritesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.GetFavorite),
            switchMap(() => this.favoriteService.getFavorites()),
            switchMap((response) => {
                const favoritesResponse = response.data && response.data !== null ? response.data : [];
                this.filterDate(favoritesResponse);

                return of(
                    new SetFavorite(favoritesResponse),
                    new FillMarkets()
                );
            }),
            catchError(() => of({ type: '[API] Get Favorite Loaded Error' }))
        )
    );

    loadSinalizationsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.GetSinalization),
            switchMap(() => this.sinalizationService.getSinalizations()),
            withLatestFrom(this.store),
            switchMap(([response, store]: [ApiResponse, AppState]) => {
                const sinalizationResponse: Sinalization[] = response.data && response.data !== null ? response.data : [];

                const markets = this.getMarketsBySlug(store.markets.list);
                const sinalizations: Sinalization[] = [];
                sinalizationResponse.forEach(sinalization => {
                    const market = markets[sinalization.mns];

                    sinalization.targetValue = '';
                    if (market) {
                        const value = ((sinalization.mtv - sinalization.mcv) / sinalization.mcv) * 100;
                        const marketName = market.nm.split(' ');

                        sinalization.sid = null;
                        sinalization.name = marketName[0];
                        sinalization.info = marketName.slice(1).join(' ');
                        sinalization.country = market.ct;
                        sinalization.targetValue = value.toFixed(0);

                    } else if (sinalization.mns === 'fastQuoteOTC') {
                        sinalization.name = 'Fast Quote OTC';

                    } else if (sinalization.mns === 'fastQuoteOTCPT') {
                        sinalization.name = 'Fast Quote OTC';
                        sinalization.info = 'Price Target';
                    }

                    const itemDate = moment(sinalization.sdt, 'DD-MM-YYYY HH:mm:ss');
                    sinalization.sortDate = itemDate.valueOf();

                    sinalizations.push(sinalization);
                });

                sinalizations.sort((a, b) => b.sortDate - a.sortDate);

                return of(
                    new SetSinalization(sinalizations)
                );
            }),
            catchError(() => of({ type: '[API] Get Sinalization Loaded Error' }))
        )
    );

    userLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.UserLogin),
            withLatestFrom(this.store),
            switchMap(([payload, store]: [UserLogin, AppState]) => {

                if (store.markets.list.length) {
                    this.store.dispatch(new GetNotification());
                    this.store.dispatch(new GetFavorite());
                    this.store.dispatch(new GetAlert());
                    this.store.dispatch(new GetSinalization());
                }

                return of(
                    new SetUser(payload.user)
                );
            }),
            catchError(() => of({ type: '[API] Get Sinalization Loaded Error' }))
        )
    );

    userLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumUserActions.UserLogout),
            switchMap(() => {
                return of(
                    new SetUser(null),
                    new SetNotification([]),
                    new SetNotificationCounter(0),
                    new SetFavorite([]),
                    new SetAlert([]),
                    new SetSinalization([])
                );
            }),
            catchError(() => of({ type: '[API] Clear user' }))
        )
    );

    @Effect({ dispatch: false })
    setActiveContractRatingFlag$ = this.actions$.pipe(
        ofType(EnumUserActions.HideOnboarding),
        tap(() => {
            localStorage.setItem('emarkets-hideonboarding', '1');
        })
    );

    constructor(
        private actions$: Actions,
        private notificationService: NotificationService,
        private alertService: AlertService,
        private favoriteService: FavoriteService,
        private sinalizationService: SinalizationService,
        public store: Store<AppState>
    ) {
    }

    private filterDate(data: any[]) {
        data.forEach((item: any) => {
            const itemDate = moment(item.cd, 'DD-MM-YYYY HH:mm:ss');
            // item.cd = `${itemDate.format('HH:mm')} (${itemDate.format('DMMM')})`;
        });
    }

    private getMarketsBySlug(markets: Markets[]) {
        const marketsList = [];

        markets.forEach(market => {
            marketsList[market.ns] = market;
        });

        return marketsList;
    }
}
