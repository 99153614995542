import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, map, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
    deps: [HttpClient]
})
export class HttpService {

    private static readonly ApiEndpoint = '/api/';
    private static readonly CmsEndpoint = '/service/';

    private static readonly httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private readonly _http: HttpClient
    ) {
    }

    public getCms(service: string): Observable<any> {
        return this._http.get(`${HttpService.CmsEndpoint}${service}`).pipe(
            retry(1),
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    public getAll(service: string): Observable<any> {
        return this._http.get(`${HttpService.ApiEndpoint}${service}`).pipe(
            retry(1),
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    public getMyBackEnd(service: string): Observable<any> {
        return this._http.get(`http://localhost:3000/${service}`).pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    public loginService(): Observable<any> {
        return this._http.get(`https://jsonplaceholder.typicode.com/todos`).pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    public getbyId(service: string, id: string): Observable<any> {
        return this._http.get(`${HttpService.ApiEndpoint}${service}/${id}`).pipe(
            retry(1),
            map(this.extractData),
            catchError(this.handleError)
        );
    }

    public updatePost(service: string, object: any, id: string): Observable<any> {
        return this._http.post<any>(`${HttpService.ApiEndpoint}${service}/${id}`, JSON.stringify(object), HttpService.httpOptions).pipe(
            retry(1),
            tap(_ => console.log(`added post w/ id=${object.id}`)),
            catchError(this.handleError)
        );
    }

    public addPut(service: string, object: any): Observable<any> {
        return this._http.put(`${HttpService.ApiEndpoint}${service}`, JSON.stringify(object), HttpService.httpOptions).pipe(
            retry(1),
            tap(_ => console.log(`updated put id=${_}`)),
            catchError(this.handleError)
        );
    }

    public deleteById(service: string, id: string): Observable<any> {
        return this._http.delete<any>(`${HttpService.ApiEndpoint}${service}/${id}`, HttpService.httpOptions).pipe(
            retry(1),
            tap(_ => console.log(`deleted product id=${id}`)),
            catchError(this.handleError)
        );
    }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${String(error.error.message)}`;
        } else {
            errorMessage = `Error Code: ${String(error.status)}\nMessage: ${String(error.message)}`;
        }
        return throwError(new ErrorEvent(errorMessage));
    }
}
