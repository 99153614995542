import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree
} from '@angular/router';

import { AuthService, AuthToken } from '@edpc/edp-auth';
import { Observable, of, combineLatest } from 'rxjs';
import { catchError, switchMap, map, take, filter, mergeMap, finalize } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserLogin } from '@store/actions/user.action';
import { getLoggedUser } from '@store/selectors/user.selector';
import { Loader } from '@store/actions/config.action';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private authenticationService: AuthenticationService,
        private storage: StorageMap,
        private firebaseAuth: AngularFireAuth,
        private store: Store<AppState>,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<any> | Promise<boolean> | boolean {
        if (environment.spain) {
            return this.authenticateSpain(next);
        } else {
            return this.authenticatePortugal(next);
        }
    }

    private authenticatePortugal(next: ActivatedRouteSnapshot): Observable<any> | Promise<boolean> | boolean {
        return this.auth.getStrategyToken('sso').pipe(
            switchMap((token: AuthToken) => {
                return this.storage.get(environment.localStorageKey).pipe(
                    mergeMap((cache: AppState) => {
                        let loggedUser = null;
                        if (cache && cache.user.profile !== null) {
                            loggedUser = cache.user.profile;
                        }

                        if (token.isValid() && loggedUser
                            && loggedUser.firebaseId === token.getPayload().user_id) {

                            this.store.pipe(
                                select(getLoggedUser),
                                take(1),
                                filter(user => {
                                    return (!user ||
                                            (loggedUser.user && loggedUser.user.id !== user.id));
                                })
                            ).subscribe(() => {
                                this.store.dispatch(new UserLogin(loggedUser));
                            });

                        } else if (token.isValid()) {
                            return this.login(token);
                        } else {
                            this.store.dispatch(new UserLogin(null));
                            if (next.url[0].path !== 'markets' &&
                                next.url[0].path !== 'home' &&
                                next.url[0].path !== 'onboarding' &&
                                next.url[0].path !== '/') {

                                this.router.navigate(['home']);
                            }

                        }
                        return of(true);
                    })
                );
            }),
            catchError(() => {
                this.store.dispatch(new UserLogin(null));
                return of(true);
            }),
            finalize(() => {
                this.store.dispatch(new Loader(false));
            })
        );
    }

    private login(token: AuthToken): Observable<boolean> {
        return combineLatest([
            this.authenticationService.validateToken(token.getValue()),
            this.firebaseAuth.user
        ]).pipe(
            map(([{ success = false, data = {} }, userData ]) => {
                const providerData = userData ? userData.providerData : [];
                const user = { ...data, ...providerData.pop() };
                this.store.dispatch(new UserLogin(user));

                return success;
            }),
            catchError((error) => {
                console.log(error);
                return of(true);
            })
        );
    }

    private authenticateSpain(next: ActivatedRouteSnapshot): Observable<any> | Promise<boolean> | boolean {
        return of(this.authenticationService.isTokenValid()).pipe(
            switchMap((validated: boolean) => {
                return this.storage.get(environment.localStorageKey).pipe(
                    mergeMap((cache: AppState) => {
                        let loggedUser = null;
                        if (cache && cache.user.profile !== null) {
                            loggedUser = cache.user.profile;
                        }

                        if (validated && loggedUser) {

                            this.store.pipe(
                                select(getLoggedUser),
                                take(1),
                                filter(user => {
                                    return (!user ||
                                            (loggedUser.user && loggedUser.user.id !== user.id));
                                })
                            ).subscribe(() => {
                                this.store.dispatch(new UserLogin(loggedUser));
                            });

                            return of(true);
                        } else {
                            return this.loginActir(next.queryParams?.tokenId);
                        }
                    })
                );
            }),
            finalize(() => {
                this.store.dispatch(new Loader(false));
            })
        );
    }

    private loginActir(tokenId: string = null): Observable<boolean | UrlTree> {
        return (
            tokenId ? this.authenticationService.validateUser({ tokenId })
                    : this.authenticationService.validateToken(this.authenticationService.getToken())
        ).pipe(
            map(({ success = false, data = {} }) => {
                this.store.dispatch(new UserLogin(data));
                this.authenticationService.tokenValidated = true;

                return success ? success : this.router.parseUrl('login');
            }),
            catchError(() => {
                this.router.navigate(['login']);
                return of(false);
            })
        );
    }
}
