import { ActionReducerMap } from '@ngrx/store';
import { usersReducer } from './user.reducer';
import { marketReducer } from './market.reducer';
import { configReducer } from './config.reducer';
import { AppState } from '@store/state/app.state';

export const appReducers: ActionReducerMap<AppState, any> = {
    user: usersReducer,
    markets: marketReducer,
    config: configReducer
};
