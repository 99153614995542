<div class="container-fluid login-modal-container">
    <button type="button" class="close dialog-close" (click)="closeModal()" id="btn-dialog-close"> 
        <i class="icon-edponline-Bra-071 font-size-23 dialog-close-icon"></i>
    </button>

    <div class="row d-flex flex-column mt-5 mb-4">
        <h6 class="access-text mb-0">{{'edp.emarket.login.modal.aceder' | translate}}</h6>
        <h6 class="access-text">{{'edp.emarket.login.modal.faca.login' | translate}}</h6>
    </div>

    <div class="row">
        <a class="w-100" (click)="login()" id="btn-already-client">
            <button class="btn bg w-100">
                <span class="already-client">{{'edp.emarket.login.modal.sou.cliente' | translate}}</span>
            </button>
        </a>
    </div>

    <div class="row mt-3">
        <a href="https://www.edp.pt/empresas/" class="w-100" id="btn-not-client">
            <button class="btn bg-2 w-100">
                <span class="not-client">{{'edp.emarket.login.modal.nao.cliente' | translate}}</span>
            </button>
        </a>
    </div>
</div>
