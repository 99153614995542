<div class="edp-loader" [ngClass]="{'full': position === 'full', 'center': position === 'center'}" *ngIf="svg">
    <svg class="loader-box"
        [attr.viewBox]="svg.viewBox"
        [attr.height]="svg.height" [attr.width]="svg.width"
        xmlns="http://www.w3.org/2000/svg">
        <circle class="loader-fill" shape-rendering="geometricPrecision"
            fill="transparent"
            [attr.cx]="svg.circleFill.cx"
            [attr.cy]="svg.circleFill.cy"
            [attr.r]="svg.circleFill.r"
            [attr.stroke-width]="svg.circleFill.strokeWidth" />
        <circle class="loader-progress" shape-rendering="geometricPrecision"
            fill="transparent"
            [attr.cx]="svg.circleProgress.cx"
            [attr.cy]="svg.circleProgress.cy"
            [attr.r]="svg.circleProgress.r"
            [attr.stroke-width]="svg.circleProgress.strokeWidth"
            [attr.stroke]="svg.url" />

            <defs>
                <linearGradient id="gradient">
                    <stop offset="0%" stop-color="#28FF52"></stop>
                    <stop offset="100%" stop-color="#28FF52"></stop>
                </linearGradient>
            </defs>
    </svg>
</div>