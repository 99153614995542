import { Component, Inject, Renderer2, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@models/user.model';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { getNotificationCounter } from '@store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnDestroy {

  @Input() isLoggedIn: boolean;
  @Input() user: User;

  notificationsCounter: number;
  spain = environment.spain;

  @Output() openDialog = new EventEmitter();
  @Output() toggle = new EventEmitter();

  private unsubscribe$: Subject<void> = new Subject();

  constructor(@Inject(DOCUMENT) private readonly document: Document,
              private readonly renderer: Renderer2, public dialog: MatDialog,
              public store: Store<AppState>) {

    this.store.pipe(
      select(getNotificationCounter),
      takeUntil(this.unsubscribe$)
    ).subscribe((notificationsCounter) => {
      this.notificationsCounter = notificationsCounter;
    });
  }

  openMenu() {
    this.toggle.emit(true);
    this.renderer.addClass(this.document.body, 'disable-scroll');
  }

  openDialogLogin(): void {
    this.openDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
