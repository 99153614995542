
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ApiResponse } from '@models/api-response';

@Injectable({
  providedIn: 'root'
})

export class MarketsService {

  httpOptions = {
    'Content-Type': 'application/json'
  };

  requestOptions = {
    headers: new HttpHeaders(this.httpOptions),
  };

  constructor(private httpClient: HttpClient) { }

  private getEndpoint() {
    return `${environment.apiUrl}/markets`;
  }

  getMarketsHome(): Observable<ApiResponse> {
    const country = environment.spain ? 'ES' : 'PT';
    return this.httpClient
        .get<ApiResponse>(
            this.getEndpoint() + '/home/' + country,
            this.requestOptions
        )
        .pipe(retry(1), catchError(this.handleError));
  }

  getMarkets(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.getEndpoint(), this.requestOptions).pipe(retry(1), catchError(this.handleError));
  }

  getMarketsHistory(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.getEndpoint() + '/history', this.requestOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
