
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Notifications } from '@models/notifications';
import { ApiResponse } from '@models/api-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    httpOptions = {
        'Content-Type': 'application/json'
    };

    requestOptions = {
        headers: new HttpHeaders(this.httpOptions),
    };

    constructor(
        private readonly httpClient: HttpClient,
        private readonly swUpdate: SwUpdate,
        private readonly snackbar: MatSnackBar,
    ) { }

    private getEndpoint() {
        return `${environment.apiUrl}/notification/`;
    }

    public initialize(): void {
        this.swUpdate.available.subscribe(() => {
            const snack = this.snackbar.open('Dados foram atualizados!', 'Recarregar', {
              duration: 60000
            });

            snack.onAction().subscribe(() => {
                window.location.reload();
            });
        });
        setInterval(() =>  {
            this.swUpdate.checkForUpdate();
        }, 20000);
    }

    getNotifications(): Observable<ApiResponse> {
        return this.httpClient.get<ApiResponse>(this.getEndpoint() + 'list',
        this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    signPushNotifications(subscription: PushSubscription) {
        const params = {
            subscription: subscription.toJSON(),
            browser: {
                name: navigator.appCodeName,
                version: navigator.appVersion
            }
        };
        return this.httpClient.post<ApiResponse>(
            this.getEndpoint() + 'sign',
            params
        ).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    read(notification: Notifications) {
        const params = {
            notificationId: notification.id
        };
        return this.httpClient.post<ApiResponse>(
            this.getEndpoint() + 'read',
            params
        ).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
     }

}
