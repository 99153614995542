import { Markets } from '@models/markets';
import { MarketDetails } from '@models/marketDetail';
import { MarketFilter } from '@models/marketFilter';

export interface MarketState {
    list: Markets[];
    home: Markets[];
    details: MarketDetails[];
    filter: MarketFilter[];
}

export const initialMarketState: MarketState = {
    list: [],
    home: [],
    details: [],
    filter: []
};

export function getInitialMarketState(): MarketState {
    return initialMarketState;
}
