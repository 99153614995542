import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { PopulateService } from '@services/populate.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { PopulateState } from '@store/actions/populate.actions';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
import { showLoader } from '@store/selectors/config.selector';
import { LanguageService } from '@services/language.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { NotificationService } from '@services/notification.service';

@Component({
    selector: 'app-root',
    template: `
        <edp-loader
            [size]="'lg'"
            [position]="'full'"
            *ngIf="showLoader$ | async"
        ></edp-loader>
        <router-outlet></router-outlet>
    `,
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    errorMessage = '';

    version: string;
    public showLoader$ = this.store.select(showLoader);
    public defaultLanguage = 'pt_PT';

    constructor(
        private notificationService: NotificationService,
        private populateService: PopulateService,
        private store: Store<AppState>,
        private storage: StorageMap,
        private languageService: LanguageService,
        private readonly router: Router,
        private readonly googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.notificationService.initialize();

        this.languageService.setDefaultLang();

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.googleAnalyticsService.pageChangeEvent(event);
            }
        });
        this.setOnetrustScript();
    }

    ngOnInit(): void {
        this.storage.get(environment.localStorageKey).subscribe(() => {
            this.store.dispatch(
                new PopulateState(this.populateService.storageCache)
            );
        });
    }

    private setOnetrustScript() {
        const dataDomain = environment.spain
            ? environment.onetrust_tag_id
            : environment.onetrust_pt_tag_id;

        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        const script3 = document.createElement('script');
        if (environment.spain) {
            script1.type = 'text/javascript';
            script1.src = `https://cdn.cookielaw.org/consent/${dataDomain}/otSDKStub.js`;
            script1.setAttribute('charset', 'UTF-8');
            script1.setAttribute('data-domain-script', dataDomain);
            document.getElementsByTagName('head')[0].appendChild(script1);

            script2.type = 'text/javascript';
            script2.src = `https://cdn.cookielaw.org/consent/${dataDomain}/OtAutoBlock.js`;
            document.getElementsByTagName('head')[0].appendChild(script2);
        } else {
            script1.type = 'text/javascript';
            script1.src =
                'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
            script1.setAttribute('charset', 'UTF-8');
            script1.setAttribute('data-domain-script', dataDomain);
            document.getElementsByTagName('head')[0].appendChild(script1);
        }
        script3.type = 'text/javascript';
        script3.text = 'function OptanonWrapper(){}';
        document.getElementsByTagName('head')[0].appendChild(script3);
    }
}
