import { initialConfigState, ConfigState } from '../state/config.state';
import { ConfigActions, EnumConfigActions } from '../actions/config.action';

export function configReducer(
    state = initialConfigState,
    action: ConfigActions
): ConfigState {
    switch (action.type) {
        case EnumConfigActions.SetVariation: {
            return {
                ...state,
                variation: action.payload
            };
        }
        case EnumConfigActions.SetMaturity: {
            return {
                ...state,
                maturity: action.payload
            };
        }
        case EnumConfigActions.Loader: {
            return {
                ...state,
                loader: action.payload
            };
        }
        default:
            return state;
    }
}
