import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MessageService } from '@services/message.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent implements OnDestroy {
    @Input() center: boolean;

    public message: string;
    public type: string;
    public show = false;

    private messageSubscription: Subscription;

    constructor(
        private readonly messageService: MessageService,
        private readonly router: Router
    ) {
        this.messageSubscription = this.messageService
            .message$
            .subscribe((options) => {
                if (options) {
                    this.type = options.type;
                    this.message = options.message;
                    this.show = true;
                }
            });

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.messageService.clear();
            }
        });
    }

    public close() {
        this.show = false;
    }

    ngOnDestroy() {
        this.messageSubscription.unsubscribe();
    }
}
