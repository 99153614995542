import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { EnumMarketActions, SetMarket, SetMarketFilter, SetMarketDetails, SetMarketHome, FillMarkets } from '../actions/market.action';
import { MarketsService } from '@services/markets.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { Markets } from '@models/markets';
import { Alert } from '@models/alerts';
import { Favorite } from '@models/favorite';
import { GetNotification, GetFavorite, GetAlert, GetSinalization } from '@store/actions/user.action';

@Injectable()
export class MarketEffects {
    loadMarketsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumMarketActions.GetMarket),
            switchMap(() => this.marketsService.getMarkets()),
            withLatestFrom(this.store),
            switchMap(([response, store]) => {
                const marketsResponse = response.data && response.data !== null ? response.data.ms : [];
                const marketFilters = response.data && response.data !== null ? response.data.mf : [];


                if (store.user.profile && store.user.profile.id) {
                    this.store.dispatch(new GetNotification());
                    this.store.dispatch(new GetFavorite());
                    this.store.dispatch(new GetAlert());
                    this.store.dispatch(new GetSinalization());
                }

                return of(
                    new SetMarket(marketsResponse),
                    new SetMarketFilter(marketFilters),
                    new FillMarkets()
                );
            }),
            catchError(() => of({ type: '[API] Get Market Loaded Error' }))
        )
    );

    loadHomeMarketsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumMarketActions.GetMarketHome),
            switchMap(() => this.marketsService.getMarketsHome()),
            switchMap((response) => {
                const marketsResponse = response.data && response.data !== null ? response.data : [];

                marketsResponse.forEach((market: Markets) => {
                    if (market.vu === 'EUR/MWh') {
                        market.vu = '€/MWh';
                    } else if (market.vu === 'USD/Barril') {
                        market.vu = '$/Barril';
                    }
                });

                return of(new SetMarketHome(marketsResponse));
            }),
            catchError(() => of({ type: '[API] Get Market Loaded Error' }))
        )
    );

    loadDetailsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumMarketActions.GetMarketDetails),
            switchMap(() => this.marketsService.getMarketsHistory()),
            switchMap((response) => {
                const marketDetailsResponse = response.data && response.data !== null ? response.data : [];

                return of(new SetMarketDetails(marketDetailsResponse));
            }),
            catchError(() => of({ type: '[API] Get Market Details Loaded Error' }))
        )
    );

    fillMarketsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumMarketActions.FillMarkets),
            withLatestFrom(this.store),
            switchMap(([payload, store]) => {
                const marketList = [];

                if (store.markets.list.length) {

                    store.markets.list.forEach(market => marketList.push({...market}));

                    marketList.forEach(market => {
                        if (market.vu === 'EUR/MWh') {
                            market.vu = '€/MWh';
                        } else if (market.vu === 'USD/Barril') {
                            market.vu = '$/Barril';
                        }

                        market.hasAlert = false;
                        market.favoriteId = null;
                    });

                    if (store.user.favorite.length) {
                        this.parseFavorites(marketList, store.user.favorite);
                    }

                    if (store.user.alert.length) {
                        this.parseAlerts(marketList, store.user.alert);
                    }
                }

                return of(new SetMarket(marketList));
            }),
            catchError(() => of({ type: '[API] Fill Markets Loaded Error' }))
        )
    );

    refreshMarketsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumMarketActions.RefreshMarkets),
            withLatestFrom(this.store),
            switchMap(([payload, store]) => {
                return of(
                    new FillMarkets(),
                    // new SetMarketHome({...store.markets.home})
                );
            }),
            catchError(() => of({ type: '[API] Get Market Details Loaded Error' }))
        )
    );

    constructor(
        private actions$: Actions,
        private marketsService: MarketsService,
        public store: Store<AppState>
    ) {}

    private parseAlerts(marketList: Markets[], alerts: Alert[]): void {
        alerts.forEach(alert => {
            const marketIdx = marketList.findIndex(marketItem => marketItem.ns === alert.mns);

            if (marketIdx >= 0) {
                marketList[marketIdx].hasAlert = true;
                marketList[marketIdx].alertDate = alert.cd;
                marketList[marketIdx].alertVariation = alert.vid;
                marketList[marketIdx].alertValue = alert.val;
                marketList[marketIdx].alertId = alert.aid;
            }
        });
    }

    private parseFavorites(marketList: Markets[], favorites: Favorite[]): void {
        favorites.forEach(favorite => {
            const marketIdx = marketList.findIndex(marketItem => marketItem.ns === favorite.mns);

            if (marketIdx >= 0) {
                marketList[marketIdx].favoriteId = favorite.fid;
                marketList[marketIdx].favoriteDate = favorite.cd;
            }
        });
    }
}
