<div class="d-flex justify-content-end user-info-sidebar px-md-4 pt-md-5 pb-2">
    <div class="d-flex align-items-center">
        <div routerLink="/manageAlerts/alerts" *ngIf="isLoggedIn" class="alerts-link d-none d-md-block" id="btn-manage-alerts">
            <div class="d-flex align-items-center justify-content-end">
                <i class="icon-edponline-Bra-030 font-size-32"></i>
                <div class="notification-counter text-white font-size-11 d-flex align-items-center justify-content-center" *ngIf="notificationsCounter > 0">
                    {{ notificationsCounter }}
                </div>
            </div>
        </div>

        <div (click)="openDialogLogin()" *ngIf="!isLoggedIn" class="alerts-link d-none d-md-block" id="btn-manage-alerts">
            <div class="d-flex align-items-center justify-content-end">
                <i class="icon-edponline-Bra-030 font-size-32"></i>
                <div class="notification-counter text-white font-size-11 d-flex align-items-center justify-content-center" *ngIf="notificationsCounter > 0">
                    {{ notificationsCounter }}
                </div>
            </div>
        </div>
    </div>
</div>
