import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdpLoaderComponent } from './edp-loader.component';

@NgModule({
  declarations: [EdpLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [EdpLoaderComponent],
})
export class EdpLoaderModule { }
