<app-onboarding *ngIf="(hideOnboarding$ | async) === false; else main"></app-onboarding>

<ng-template #main>
    <app-nav-bar (toggle)="toggleMenu($event)" [isLoggedIn]="authenticationService.isLoggedIn" [user]="user"
    (openDialog)="openDialogLogin()"></app-nav-bar>

    <app-menu [isMenuOpened]="menuOpened" [isLoggedIn]="authenticationService.isLoggedIn" [user]="user" (toggle)="toggleMenu($event)"
    (openDialog)="openDialogLogin()" (logOut)="logout()"></app-menu>

    <app-desktop-nav-bar [isLoggedIn]="authenticationService.isLoggedIn" (openDialog)="openDialogLogin()"></app-desktop-nav-bar>

    <app-side-bar [isLoggedIn]="authenticationService.isLoggedIn" [user]="user" (openDialog)="openDialogLogin()" (logOut)="logout()"></app-side-bar>

    <div *ngIf="networkStatus$ | async; else offline">
    </div>
    <ng-template #offline>
        <div class="row offline-div d-flex justify-content-center align-items-center" *ngIf="offline">
            <span>{{'edp.emarket.layout' | translate}}</span>
        </div>
    </ng-template>

    <router-outlet></router-outlet>

    <app-message-box></app-message-box>
</ng-template>
