import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthenticationService } from "@services/authentication.service";

@Component({
    selector: "app-login-modal",
    templateUrl: "./login-modal.component.html",
    styleUrls: ["./login-modal.component.scss"],
})
export class LoginModalComponent {
    public ssoUrl: string;

    constructor(
        public dialogRef: MatDialogRef<LoginModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any,
        public authenticationService: AuthenticationService
    ) {
        this.ssoUrl = this.authenticationService.getSsoUrl();
    }

    actionFunction(): void {
        this.closeModal();
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    login(): void {
        window.location.href = this.ssoUrl;
    }
}
