import { Variation } from '@models/variation';
import { Maturity } from '@models/maturity';

export interface ConfigState {
    variation: Variation[];
    maturity: Maturity[];
    loader: boolean;
}

export const initialConfigState: ConfigState = {
    variation: null,
    maturity: null,
    loader: true,
};

export function getInitialConfigState(): ConfigState {
    return initialConfigState;
}
