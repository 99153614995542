import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketFilterPipe } from './market-filter.pipe';
import { MarketInfoPipe } from './market-info.pipe';
import { MarketNamePipe } from './market-name.pipe';

@NgModule({
    declarations: [MarketFilterPipe, MarketNamePipe, MarketInfoPipe],
    imports: [CommonModule],
    exports: [MarketFilterPipe, MarketNamePipe, MarketInfoPipe],
})
export class PipesModule {}
