<div id="onboarding-slider" class="carousel onboarding slide d-flex justify-content-center" data-ride="carousel" data-interval="false" data-wrap="false">

    <ul class="carousel-indicators align-items-center">
        <li data-target="#onboarding-slider" 
            *ngFor="let item of items; let i = index" 
            data-slide-to="i" 
            (click)="counter = i" 
            [ngClass]="{'active' : i === 0}"
            id="onboarding-slide-{{i}}">
        </li>
    </ul>

    <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let item of items; let i = index" [ngClass]="{'active' : i === 0}">
            <div class="d-flex justify-content-center img-container">
                <img src="/assets/{{ item.imgUrl | translate }}" class="w-100 onboarding-image" alt="{{ item.alt | translate }}">
            </div>
            <div class="d-flex justify-content-center align-items-start">
                <p class="text-center">{{ item.text1 | translate }} <span>{{ item.text2 | translate }}</span></p>
            </div>
        </div>
    </div>

    <div class="next-slide">
        <a href="#onboarding-slider" data-slide="next" *ngIf="sliding">
            <button class="btn bg" (click)="nextSlide($event)" id="btn-next-slide">
                <span class="bg-text">{{'edp.emarket.novo.alerta.proximo' | translate}}</span>
            </button>
        </a>
    </div>

</div>
