import { Component, Input, Output, EventEmitter, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { User } from '@models/user.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() isLoggedIn: boolean;
  @Input() isMenuOpened: boolean;
  @Input() user: User;

  @Output() toggle = new EventEmitter();
  @Output() openDialog = new EventEmitter();
  @Output() logOut = new EventEmitter();

  constructor(@Inject(DOCUMENT) private readonly document: Document,
              private readonly renderer: Renderer2) {}

  closeMenu() {
    this.toggle.emit(!this.isMenuOpened);
    this.renderer.removeClass(this.document.body, 'disable-scroll');
  }

  openDialogLogin(): void {
    this.openDialog.emit(true);
  }

  logout(): void {
      this.logOut.emit(true);
  }

}
