import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { User, UserAuthenticate } from "@models/user.model";
import { AuthService } from "@edpc/edp-auth";
import { mergeMap, map, catchError } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { AppState } from "@store/state/app.state";
import { UserLogin, UserLogout } from "@store/actions/user.action";
import { getLoggedUser } from "@store/selectors/user.selector";
import { Loader } from "@store/actions/config.action";
import { ApiLogin } from "@models/api-response";
import { Router } from "@angular/router";

@Injectable()
export class AuthenticationService {
    public isLoggedIn = false;

    public tokenValidated = false;
    public tokenKey = "emarket_token";

    constructor(
        private readonly auth: AuthService,
        private readonly http: HttpClient,
        public store: Store<AppState>,
        private readonly router: Router
    ) {
        this.store.pipe(select(getLoggedUser)).subscribe((user) => {
            this.isLoggedIn = user !== null;
        });
    }

    public validateToken(token: string): Observable<any> {
        const endpoint = environment.spain
            ? `${environment.apiUrl}/auth/es/validate`
            : `${environment.apiUrl}/auth/login`;
        return this.http.post(endpoint, {
            tokenId: token,
        });
    }

    public validateUser(userAuth: UserAuthenticate): Observable<any> {
        this.store.dispatch(new Loader(true));
        return this.http
            .post(`${environment.apiUrl}/auth/es/login`, userAuth)
            .pipe(
                map((resp: ApiLogin) => {
                    this.store.dispatch(new UserLogin(resp.data));
                    this.setToken(resp.token);
                    this.tokenValidated = true;

                    this.router.navigate(["home"]);

                    return resp;
                }),
                catchError(() => {
                    this.store.dispatch(new Loader(false));
                    return of(false);
                })
            );
    }

    public getToken(): string {
        return localStorage.getItem(this.tokenKey);
    }

    public setToken(token: string): void {
        localStorage.setItem(this.tokenKey, token);
    }

    has(): boolean {
        return !!this.getToken();
    }

    public isTokenValid(): boolean {
        return this.tokenValidated;
    }

    public removeToken(): void {
        localStorage.removeItem(this.tokenKey);
        this.tokenValidated = false;
    }

    public getSsoUrl(): string {
        return `${environment.ssoUrl}/?referer=${location.href}`;
    }

    public logout(user: User): Observable<any> {
        return this.auth.logout("sso").pipe(
            mergeMap(() => {
                this.store.dispatch(new UserLogout());
                return this.http.post(`${environment.apiUrl}/auth/logout`, {
                    uid: user.firebaseId,
                });
            })
        );
    }
}
