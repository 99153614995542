import { initialUserState, UserState } from '@store/state/user.state';
import { UserActions, EnumUserActions } from '@store/actions/user.action';

export function usersReducer(
    state = initialUserState,
    action: UserActions
): UserState {
    switch (action.type) {
        case EnumUserActions.SetUser: {
            return {
                ...state,
                profile: action.user
            };
        }
        case EnumUserActions.SetAlert: {
            return {
                ...state,
                alert: action.alerts
            };
        }
        case EnumUserActions.SetFavorite: {
            return {
                ...state,
                favorite: action.favorites
            };
        }
        case EnumUserActions.SetNotification: {
            return {
                ...state,
                notification: action.notifications
            };
        }
        case EnumUserActions.SetNotificationCounter: {
            return {
                ...state,
                notificationCounter: action.notificationCounter
            };
        }
        case EnumUserActions.SetSinalization: {
            return {
                ...state,
                sinalization: action.sinalizations
            };
        }
        case EnumUserActions.HideOnboarding: {
            return {
                ...state,
                hideOnboarding: action.hide
            };
        }
        default:
            return state;
    }
}
