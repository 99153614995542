// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    spain: false,
    spainDomain: 'emarkets.edpenergia.es',
    onetrust_tag_id: 'caa49198-6403-462c-9e71-fc63e68e7bc2',
    onetrust_pt_tag_id: 'e1d6a3a1-2c8e-4ace-ba67-2f44ad3cd46b',
    baseHref: '',
    apiUrl: '/api',
    appUrl: 'https://emarkets.edponline.edp.pt/',
    ssoUrl: 'https://login.edp.pt',
    url: 'https://emarkets.edponline.edp.pt/',
    localStorageKey: '__edp_emarkets_storage__',
    vapidKey:
        'BDQCg1UOQNz5MF22oabGzFMXGsJ876MjMkm1fIistga_dhkXXfqD-zAB-tMhltLO06BYAa1NB6OLXxcQb3g-ABs',
    firebase: {
        apiKey: 'AIzaSyBCyKe7wskhsEcIHK7C_FVQlJLlOBJPda8',
        authDomain: 'loginedp-edp-prd.firebaseapp.com',
        databaseURL: 'https://loginedp-edp-prd.firebaseio.com',
        projectId: 'loginedp-edp-prd',
    },
    
};
