import { Component, OnDestroy } from '@angular/core';
import { User } from '@models/user.model';
import { Subject, Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';
import { getLoggedUser, hideOnboarding } from '@store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { LoginModalComponent } from '@components/login-modal/login-modal.component';
import { GetMarketDetails, GetMarket, GetMarketHome } from '@store/actions/market.action';
import { Network } from '@ngx-pwa/offline';
import { GetConfig } from '@store/actions/config.action';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NotificationService } from '@services/notification.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {

    public menuOpened = false;
    public user: User;
    public hideOnboarding$ = this.store.select(hideOnboarding);

    networkStatus$: Observable<boolean>;

    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        public dialog: MatDialog,
        public store: Store<AppState>,
        public authenticationService: AuthenticationService,
        private router: Router,
        protected network: Network,
        private swPush: SwPush,
        private notificationService: NotificationService
    ) {

        this.store.pipe(
            select(getLoggedUser),
            takeUntil(this.unsubscribe$)
        ).subscribe(user => {
            if (user) {
                this.subscribeToNotifications();
                this.user = user;
            }
        });

        this.networkStatus$ = this.network.onlineChanges;

        this.networkStatus$.subscribe((status: boolean) => {
            if (status) {
                this.store.dispatch(new GetMarketHome());
                this.store.dispatch(new GetMarket());
                this.store.dispatch(new GetMarketDetails());
                this.store.dispatch(new GetConfig());
            }
        });
    }

    public openDialogLogin(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '93.5%';
        dialogConfig.data = {
            content: 'login'
        };
        dialogConfig.position = { top: `161px` };
        this.dialog.open(LoginModalComponent, dialogConfig);
    }

    public logout(): void {
        this.authenticationService.logout(this.user).subscribe(data => {
            if (data.success) {
                this.authenticationService.removeToken();

                this.router.navigate([
                    environment.spain ? 'login' : 'home'
                ]);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public toggleMenu(event): void {
        this.menuOpened = event;
    }

    subscribeToNotifications(): void {
        this.swPush.requestSubscription({
            serverPublicKey: environment.vapidKey
        })
        .then(subscription => {
            this.notificationService.signPushNotifications(subscription).subscribe();
        })
        .catch((error) => {
            console.log(error);
        });
    }

}
