import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RefreshMarkets } from '@store/actions/market.action';
import { AppState } from '@store/state/app.state';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public supportedLanguages = ['pt_PT', 'es_ES', 'en_EN'];

    private readonly LANGUAGE_COOKIE = 'emarkets.locale';
    private languageSubject: BehaviorSubject<string>;

    constructor(
        private readonly translateService: TranslateService,
        private readonly store: Store<AppState>,
    ) {
        const currentLanguage = this.getLanguage();
        this.languageSubject = new BehaviorSubject<string>(
            currentLanguage
        );
        this.updateCurrentLanguage(currentLanguage);
    }

    updateCurrentLanguage(currentLanguage: string): void {
        localStorage.setItem(this.LANGUAGE_COOKIE, currentLanguage);
        this.translateService.use(currentLanguage);
        this.languageSubject.next(currentLanguage);
        moment.locale(currentLanguage.replace('_', '-'));
        this.store.dispatch(new RefreshMarkets());
    }

    public setDefaultLang(): void {
        this.translateService.setDefaultLang(this.getLanguage());
    }

    public getLanguage(): string {
        let languageKey = localStorage.getItem(this.LANGUAGE_COOKIE);
        if (!languageKey) {
            languageKey = environment.spain ? 'es_ES' : 'pt_PT';
        }
        return languageKey;
    }

    public languageChange(): BehaviorSubject<string> {
        return this.languageSubject;
    }
}
