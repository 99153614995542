import { createSelector } from '@ngrx/store';
import { ConfigState } from '../state/config.state';
import { AppState } from '@store/state/app.state';

const selectConfig = (state: AppState): ConfigState => state.config;

export const getVariation = createSelector(
    selectConfig,
    (state: ConfigState) => state.variation
);

export const getMaturity = createSelector(
    selectConfig,
    (state: ConfigState) => state.maturity
);

export const showLoader = createSelector(
    selectConfig,
    (state: ConfigState) => state.loader
);
