import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { Subject } from 'rxjs';
import { getNotificationCounter } from '@store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-desktop-nav-bar',
  templateUrl: './desktop-nav-bar.component.html',
  styleUrls: ['./desktop-nav-bar.component.scss']
})
export class DesktopNavBarComponent implements OnDestroy {

  @Input() isLoggedIn: boolean;
  @Output() openDialog = new EventEmitter();

  notificationsCounter: number;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(public store: Store<AppState>) {
    this.store.pipe(
      select(getNotificationCounter),
      takeUntil(this.unsubscribe$)
    ).subscribe((notificationsCounter) => {
      this.notificationsCounter = notificationsCounter;
    });
  }

  openDialogLogin(): void {
    this.openDialog.emit(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
