import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SplashGuard implements CanActivate {

    constructor(
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<any> | Promise<any> | UrlTree | boolean {
        if ((next.url[0].path === 'splash' && window.innerWidth >= 768)) {
            return this.router.createUrlTree(['/home'], { queryParams: next.queryParams});
        }

        return true;
    }
}
