import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss']
})
export class SplashscreenComponent {
  public spain = environment.spain;

  constructor(private _router: Router) {
    setTimeout(() => {
      this._router.navigate(['/home'], {queryParamsHandling: 'preserve' });
    }, 2000);
  }
}
