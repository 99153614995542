import { Action } from '@ngrx/store';
import { Markets } from '@models/markets';
import { MarketDetails } from '@models/marketDetail';
import { MarketFilter } from '@models/marketFilter';

export enum EnumMarketActions {
    SetMarket = '[Markets] Setting markets',
    GetMarket = '[Markets] Get markets',
    SetMarketHome = '[Markets] Setting markets home',
    GetMarketHome = '[Markets] Get markets home',
    GetMarketDetails = '[Market] Get market details',
    SetMarketDetails = '[Market] Setting market details',
    SetMarketFilter = '[MarketFilter] Setting marker',
    FillMarkets = '[Markets] Fill market list with favorites and alerts',
    RefreshMarkets = '[Markets] Refresh market list on store'
}

export class SetMarket implements Action {
    public readonly type = EnumMarketActions.SetMarket;
    constructor(public markets: Markets[]) {}
}

export class GetMarket implements Action {
    public readonly type = EnumMarketActions.GetMarket;
}

export class SetMarketHome implements Action {
    public readonly type = EnumMarketActions.SetMarketHome;
    constructor(public markets: Markets[]) {}
}

export class GetMarketHome implements Action {
    public readonly type = EnumMarketActions.GetMarketHome;
}

export class GetMarketDetails implements Action {
    public readonly type = EnumMarketActions.GetMarketDetails;
}

export class SetMarketDetails implements Action {
    public readonly type = EnumMarketActions.SetMarketDetails;
    constructor(public marketDetails: MarketDetails[]) {}
}

export class SetMarketFilter implements Action {
    public readonly type = EnumMarketActions.SetMarketFilter;
    constructor(public marketFilters: MarketFilter[]) {}
}

export class FillMarkets implements Action {
    public readonly type = EnumMarketActions.FillMarkets;
}

export class RefreshMarkets implements Action {
    public readonly type = EnumMarketActions.RefreshMarkets;
}

export type MarketActions = SetMarket
                          | GetMarket
                          | SetMarketHome
                          | GetMarketHome
                          | SetMarketDetails
                          | SetMarketFilter
                          | FillMarkets
                          | RefreshMarkets;
