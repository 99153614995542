import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { User } from '@models/user.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

    @Input() isLoggedIn: boolean;
    @Input() user: User;

    @Output() openDialog = new EventEmitter();
    @Output() logOut = new EventEmitter();

    public isNotSpain: boolean;
    
    public ngOnInit(): void {
        this.isNotSpain = (environment.spain === false);
    }

    openDialogLogin(): void {
        this.openDialog.emit(true);
    }

    logout(): void {
        this.logOut.emit(true);
    }

}
