<div class="signaling-sent d-flex align-items-center {{type}}" [ngClass]="{'center': center}" *ngIf="show">
    <div class="d-flex justify-content-between w-100 px-3">
        <div class="d-flex">
            <span [innerHTML]="message | translate"></span>
        </div>
        <div class="d-flex align-items-center">
            <a (click)="close()" id="close-message-box">
                <span class="ok">OK</span>
            </a>
        </div>
    </div>
</div>
