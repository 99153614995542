import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Loader } from '@store/actions/config.action';
import { AppState } from '@store/state/app.state';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent {
    public spain = environment.spain;

    constructor(public store: Store<AppState>) {
        this.store.dispatch(new Loader(false));
    }
}
