
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ApiResponse } from '@models/api-response';

@Injectable({
    providedIn: 'root'
})

export class AlertService {

    httpOptions = {
        'Content-Type': 'application/json'
    };

    requestOptions = {
        headers: new HttpHeaders(this.httpOptions),
    };

    constructor(private httpClient: HttpClient) { }

    private getEndpoint() {
        return `${environment.apiUrl}/alert`;
    }

    createAlert(alert): Observable<ApiResponse> {
        return this.httpClient.post<ApiResponse>(this.getEndpoint(),
        alert, this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    getAlerts(): Observable<ApiResponse> {
        return this.httpClient.get<ApiResponse>(this.getEndpoint(),
        this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    updateAlert(alert): Observable<ApiResponse> {
        return this.httpClient.put<ApiResponse>(this.getEndpoint(),
        alert, this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    deleteAlert(alert): Observable<ApiResponse> {
        return this.httpClient.post<ApiResponse>(this.getEndpoint(),
        alert, this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    deleteAllAlerts(): Observable<ApiResponse> {
        return this.httpClient.post<ApiResponse>(this.getEndpoint() + '/deleteAll',
        {}, this.requestOptions).pipe(retry(1), catchError(this.handleError));
    }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
     }

}
