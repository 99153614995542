import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { EnumConfigActions, SetVariation, SetMaturity } from '../actions/config.action';
import { ConfigService } from '@services/config.service';

@Injectable()
export class ConfigEffects {
    loadInitialData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumConfigActions.GetConfig),
            switchMap(() => this.configService.getConfig()),
            switchMap((response) => {
                return of(
                    new SetVariation(response.data.variation),
                    new SetMaturity(response.data.maturity)
                );
            }),
            catchError(() => of({ type: '[Filters API] Alerts Loaded Error' }))
        )
    );

    constructor(
        private actions$: Actions,
        private configService: ConfigService) {}
}
