import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomTranslateLoaderFactory, TranslationService } from './translation.service';


@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: CustomTranslateLoaderFactory,
                deps: [TranslationService],
            },
        }),
    ],
    providers: [],
    exports: [TranslateModule],
})
export class TranslationModule {}
