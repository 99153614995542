import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SplashscreenComponent } from '@pages/splashscreen/splashscreen.component';
import { AuthGuard } from '@guards/authentication.guard';
import { SplashGuard } from '@guards/splashScreen.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AppCallbackComponent } from '@components/callback/callback.component';
import { LoginComponent } from '@pages/login/login.component';
import { CookiesComponent } from '@pages/cookies/cookies.component';
import { SpainGuard } from '@guards/spain.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'splash',
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: '@pages/home/home.module#HomeModule',
                canActivate: [AuthGuard],
                data: {
                    title: 'Inicio',
                },
            },
            {
                path: 'markets',
                loadChildren: '@pages/mercados/mercados.module#MercadosModule',
                canActivate: [AuthGuard],
                data: {
                    title: 'Mercados',
                },
            },
            {
                path: 'favorites',
                loadChildren: '@pages/favoritos/favoritos.module#FavoritosModule',
                canActivate: [AuthGuard],
                data: {
                    title: 'Favoritos',
                },
            },
            {
                path: 'manageAlerts',
                loadChildren: '@pages/gerir-alertas/gerir-alertas.module#GerirAlertasModule',
                canActivate: [AuthGuard],
                data: {
                    title: 'Alertas',
                },
            },
            {
                path: 'signalings',
                loadChildren: '@pages/sinalizacoes/sinalizacoes.module#SinalizacoesModule',
                canActivate: [AuthGuard],
                data: {
                    title: 'Sinalizações',
                },
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'splash',
        component: SplashscreenComponent,
        canActivate: [SplashGuard],
    },
    {
        path: 'callback',
        component: AppCallbackComponent,
    },
    {
        path: 'cookies',
        component: CookiesComponent,
        canActivate: [SpainGuard],
    },
    {
        path: '**',
        redirectTo: 'home',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
