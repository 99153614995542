import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core';

export enum SizeEnum {
    sm = 'sm',
    md = 'md',
    lg = 'lg',
}

@Component({
    selector: 'edp-loader',
    templateUrl: './edp-loader.component.html',
    styleUrls: ['./edp-loader.component.scss'],
})
export class EdpLoaderComponent implements OnInit, OnDestroy {
    @Input() public size: any;
    @Input() public position: string;
    @Input() public backgroundColor: string;
    public svg = null;
    public isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

    constructor(private renderer: Renderer2) {}

    ngOnInit() {
        if (this.position === 'full') {
            this.renderer.addClass(document.body, 'overflow-hidden');
        }
        this.render();
    }
    ngOnDestroy() {
        if (this.position === 'full') {
            this.renderer.removeClass(document.body, 'overflow-hidden');
        }
    }

    private render = () => {
        switch (this.size) {
            case SizeEnum.sm:
                this.svg = this.applySvg(40);
                break;
            case SizeEnum.md:
                this.svg = this.applySvg(60);
                break;
            default:
                this.svg = this.applySvg(80);
                break;
        }
    }

    private applySvg = (boxSize = 80): object => {
        const href = `${location.href}`;

        return {
            url: `url("${href}#gradient")`,
            viewBox: `0 0 ${boxSize} ${boxSize}`,
            width: boxSize,
            height: boxSize,
            circleFill: {
                strokeWidth: boxSize / 2 / 10,
                cx: boxSize / 2,
                cy: boxSize / 2,
                r: boxSize / 2 - boxSize / 2 / 10,
            },
            circleProgress: {
                strokeWidth: boxSize / 10 - 1,
                cx: boxSize / 2,
                cy: boxSize / 2,
                r: boxSize / 2 - boxSize / 2 / 10,
            },
        };
    }
}
