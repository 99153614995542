import { Action } from '@ngrx/store';
import { Variation } from '@models/variation';
import { Maturity } from '@models/maturity';

export enum EnumConfigActions {
    SetVariation = '[Config] Setting variation',
    SetMaturity = '[Config] Setting maturity',
    GetConfig = '[Config] Get Config',
    Loader = '[Config] Loader Status'
}

export class SetVariation implements Action {
    public readonly type = EnumConfigActions.SetVariation;
    constructor(public payload: Variation[]) {}
}

export class SetMaturity implements Action {
    public readonly type = EnumConfigActions.SetMaturity;
    constructor(public payload: Maturity[]) {}
}

export class GetConfig implements Action {
    public readonly type = EnumConfigActions.GetConfig;
}

export class Loader implements Action {
    public readonly type = EnumConfigActions.Loader;
    constructor(public payload: boolean) {}
}

export type ConfigActions = | SetVariation
                            | SetMaturity
                            | GetConfig
                            | Loader;
