import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language.service';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent {
  public languages: string[];
  public languageKey: string;

  constructor(
    private readonly languageService: LanguageService,
    public translate: TranslateService,
    ) {
    this.languages = this.languageService.supportedLanguages;
    this.languageKey = this.languageService.getLanguage();
  }

  public onSelect(event: any): void {
    const value = event.value;

    this.languageService.updateCurrentLanguage(value);
  }

}
