<span class="span-label">{{'edp.emarket.idioma' | translate}}</span>
<mat-form-field class="form-select-lang" floatLabel="never">
  <mat-label class="select-text">{{'edp.emarket.'+languageKey | translate}}</mat-label>
  <mat-select panelClass="select-lang" class="white-color" (selectionChange)="onSelect($event)">
    <mat-option *ngFor="let language of languages" value="{{ language }}">
      <div class="d-flex justify-content-between align-items-center">
        {{'edp.emarket.' + language | translate}}
        <ng-container *ngIf="language === 'pt_PT'" >
          <div class="traco">
            <i class="icon-edponline-Bra-073 font-size-24 font-weight-bold pr-2 rotate-180 edit-alerts-arrow"></i>
          </div>
        </ng-container>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>



