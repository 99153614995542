import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AggregationModule } from './services/dependencies-module';

import { HttpService } from './services/http.service';
import { GlobalInterceptor } from './services/interceptor.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { NovoAlertaComponent } from '@pages/novo-alerta/novo-alerta.component';

import { NovaSinalizacaoComponent } from '@pages/nova-sinalizacao/nova-sinalizacao.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SplashscreenComponent } from '@pages/splashscreen/splashscreen.component';
import { OnboardingComponent } from '@pages/onboarding/onboarding.component';

import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { PopupComponent } from './components/popup/popup.component';
import { PopupAlertsComponent } from './components/popup-alerts/popup-alerts.component';
import { DetalheComponent } from '@pages/detalhe/detalhe.component';
import { SinalizationDetailComponent } from '@pages/sinalization-detail/sinalization-detail.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighchartComponent } from '@pages/highchart/highchart.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessageBoxComponent } from '@components/message-box/message-box.component';
import { VariationSelectComponent } from './components/variation-select/variation-select.component';
import { PriceInputComponent } from './components/price-input/price-input.component';
import { FastQuoteComponent } from '@pages/fast-quote/fast-quote.component';
import { FastQuotePendingComponent } from '@pages/fast-quote-pending/fast-quote-pending.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';

import { AuthModule, SingleSignOnAuthStrategy, AuthJWTToken } from '@edpc/edp-auth';
import { environment } from 'src/environments/environment';
import { PopulateService } from '@services/populate.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthenticationService } from '@services/authentication.service';

export function getInitialState(storage: StorageMap, populate: PopulateService): Subscription {
  return storage.get(environment.localStorageKey).subscribe((cache: any) => (populate.storageCache = cache));
}
import { MenuComponent } from '@components/menu/menu.component';
import { EditarAlertasComponent } from '@pages/editar-alertas/editar-alertas.component';
import { DesktopNavBarComponent } from '@components/desktop-nav-bar/desktop-nav-bar.component';
import { StoreModule, INITIAL_STATE, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { appReducers } from '@store/reducers/app.reducers';
import { storageMetaReducer } from '@store/reducers/storage.reducer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '@store/effects/user.effects';
import { MarketEffects } from '@store/effects/market.effects';
import { ConfigEffects } from '@store/effects/config.effect';
import { PopulateEffects } from '@store/effects/populate.effects';
import { EdpLoaderModule } from '@components/edp-loader/edp-loader.module';
import { Subscription } from 'rxjs';
import { GlobalErrorHandler } from '@services/error-handler.service';
import { BackButtonDirective } from './directives/back-button.directive';
import { LoginComponent } from './pages/login/login.component';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { TranslationModule } from '@components/translation/translation.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PipesModule } from './pipes/pipes.module';

registerLocaleData(localePt);

export function ConfigFactory() {
    return (): Promise<void> => {
        return new Promise<void>((resolve) => {
            environment.spain = new RegExp(environment.spainDomain, 'i').test(window.location.host);
            resolve();
        });
    };
}
@NgModule({
    declarations: [
        AppComponent,
        NovoAlertaComponent,
        NovaSinalizacaoComponent,
        NavBarComponent,
        SideBarComponent,
        SplashscreenComponent,
        OnboardingComponent,
        LoginModalComponent,
        PopupComponent,
        PopupAlertsComponent,
        DetalheComponent,
        SinalizationDetailComponent,
        HighchartComponent,
        ConfirmDialogComponent,
        MessageBoxComponent,
        VariationSelectComponent,
        PriceInputComponent,
        FastQuoteComponent,
        FastQuotePendingComponent,
        LayoutComponent,
        MenuComponent,
        EditarAlertasComponent,
        DesktopNavBarComponent,
        BackButtonDirective,
        LoginComponent,
        SelectLanguageComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AggregationModule,
        HighchartsChartModule,
        EdpLoaderModule,
        PipesModule,
        TranslationModule,
        StoreModule.forRoot(appReducers),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([UserEffects, MarketEffects, ConfigEffects, PopulateEffects]),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AuthModule.forRoot({
            strategies: [
                SingleSignOnAuthStrategy.setup({
                    name: 'sso',
                    baseEndpoint: '/api',
                    token: {
                        endpoint: 'pt.edp.sso.firebase.validateToken',
                        requireValidToken: true,
                        class: AuthJWTToken,
                    },
                    authorize: {
                        redirectUri: environment.ssoUrl,
                    },
                    revoke: {
                        endpoint: 'pt.edp.sso.firebase.revokeToken',
                    },
                    bootstrap: false
                }),
            ],
        }),
        ServiceWorkerModule.register('edp-service-worker.js', { enabled: true }),
        GoogleTagManagerModule.forRoot({
            id: environment.spain ? 'UA-7284264-6' : 'GTM-N35F56D',
        })
    ],
    exports: [
        AggregationModule
    ],
    providers: [
        HttpService,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-PT'
        },
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigFactory,
            multi: true,
        },
        {
            provide: USER_PROVIDED_META_REDUCERS,
            useFactory: storageMetaReducer,
            deps: [StorageMap],
        },
        { provide: INITIAL_STATE, useFactory: getInitialState, deps: [StorageMap, PopulateService] },
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LoginModalComponent,
        PopupComponent,
        PopupAlertsComponent,
        ConfirmDialogComponent
    ]
})

export class AppModule { }
