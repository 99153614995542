import { Action } from '@ngrx/store';

export enum EnumPopulateStateActions {
    PopulateState = '[Populate] Populate state when refresh page'
}

export class PopulateState implements Action {
    public readonly type = EnumPopulateStateActions.PopulateState;
    constructor(public payload: any) {}
}

export type PopulateStateActions = PopulateState;
