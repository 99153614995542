import { initialMarketState, MarketState } from '@store/state/market.state';
import { MarketActions, EnumMarketActions } from '@store/actions/market.action';

export function marketReducer(
    state = initialMarketState,
    action: MarketActions
): MarketState {
    switch (action.type) {
        case EnumMarketActions.SetMarket: {
            return {
                ...state,
                list: action.markets
            };
        }
        case EnumMarketActions.SetMarketHome: {
            return {
                ...state,
                home: action.markets
            };
        }
        case EnumMarketActions.SetMarketDetails: {
            return {
                ...state,
                details: action.marketDetails
            };
        }
        case EnumMarketActions.SetMarketFilter: {
            return {
                ...state,
                filter: action.marketFilters
            };
        }
        default:
            return state;
    }
}
