import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationKeyValues } from './translation.model';
import { TranslationService } from './translation.service';

export class CustomTranslateLoader implements TranslateLoader {
    private constructor(private translationService: TranslationService) {
    }
    static of(translationService: TranslationService): CustomTranslateLoader {
      return new CustomTranslateLoader(translationService);
    }
    getTranslation(language: string): Observable<TranslationKeyValues> {
      return this.translationService.getTranslation(language);
    }
}
