<div class="collapse navbar-collapse order-3 topPart" [ngClass]="{ 'show': isMenuOpened }">
    <div class="d-flex justify-content-between mb-3 margin-top-46">
        <div [ngClass]="{ 'd-flex align-items-center': isLoggedIn }">
            <div class="foto-perfil ml-4 d-flex align-items-center justify-content-center">
                <i class="icon-edponline-Bra-081 font-size-36"></i>
            </div>
            <div class="d-flex flex-column margin-left-20 user-info-mobile" *ngIf="isLoggedIn">
                <span class="user-name-text">{{ user.firstname }} {{ user.lastname }}</span>
                <span class="user-email-text">{{ user.email }}</span>
            </div>
        </div>
        <span class="fontSize25 mr-3 mt-2">
            <i class="icon-edponline-Bra-071 font-size-23 text-white mb-4 close-icon" (click)="closeMenu()" id="close-menu"></i>
        </span>
    </div>
</div>

<div class="collapse navbar-collapse order-3 bottom-part-invisible" [ngClass]="{ 'show': isMenuOpened }">

</div>

<div class="collapse navbar-collapse order-3 bottomPart" [ngClass]="{ 'show': isMenuOpened }">
    <ul class="navbar-nav margin-top-36">
        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" routerLink="/home" (click)="closeMenu()" id="nav-home">
            <i class="icon-edponline-Bra-018 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.home' | translate}}</span>
        </a>
        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" routerLink="/markets" (click)="closeMenu()" id="nav-markets">
            <i class="icon-edponline-Bra-023 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.mercados' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" routerLink="/favorites" (click)="closeMenu()" *ngIf="isLoggedIn" id="nav-favorites">
            <i class="icon-edponline-Bra-029 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.favoritos' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-favorites">
            <i class="icon-edponline-Bra-029 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.favoritos' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" routerLink="/manageAlerts" (click)="closeMenu()" *ngIf="isLoggedIn" id="nav-manage-alerts">
            <i class="icon-edponline-Bra-149 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.alertas' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-manage-alerts">
            <i class="icon-edponline-Bra-149 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.alertas' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" routerLink="/signalings" (click)="closeMenu()" *ngIf="isLoggedIn" id="nav-signalings">
            <i class="icon-edponline-Bra-094 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.sinalizacoes' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-4"
        routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-signalings">
            <i class="icon-edponline-Bra-094 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.sinalizacoes' | translate}}</span>
        </a>
    </ul>

    <div class="select-l pl-5">
      <app-select-language></app-select-language>
    </div>

    <div class="modal-footer-custom w-100">
        <div class="d-flex align-items-center pl-3 py-3 w-100">
            <i class="icon-edponline-Bop-137 login-icon" [ngClass]="isLoggedIn ? 'rotate-90' : 'rotate-270'"></i>
            <span class="menu-item-session w-100 ml-3" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="btn-login">
              {{'edp.emarket.menu.iniciar.sessao' | translate}}
            </span>

            <span class="menu-item-session w-100 ml-3" (click)="logout()" *ngIf="isLoggedIn" id="btn-logout">
              {{'edp.emarket.menu.terminar.sessao' | translate}}
            </span>
        </div>
    </div>
</div>
