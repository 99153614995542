import { Action } from '@ngrx/store';
import { User } from '@models/user.model';
import { Alert } from '@models/alerts';
import { Favorite } from '@models/favorite';
import { Notifications } from '@models/notifications';
import { Sinalization } from '@models/sinalization';

export enum EnumUserActions {
    UserLogin = '[User] Setting logged user',
    UserLogout = '[User] Logout user',
    SetUser = '[User] Setting user',
    GetAlert = '[Alerts] Get alerts',
    SetAlert = '[Alerts] Setting alerts',
    GetFavorite = '[Favorite] Get favorite',
    SetFavorite = '[Favorite] Setting favorite',
    GetNotification = '[Notification] Get notification',
    SetNotification = '[Notification] Setting notification',
    SetNotificationCounter = '[NotificationCounter] Setting notification',
    GetSinalization = '[Sinalization] Get notification',
    SetSinalization = '[Sinalization] Setting notification',
    HideOnboarding = '[Onboarding] Hide onboarding'
}

export class UserLogin implements Action {
    public readonly type = EnumUserActions.UserLogin;
    constructor(public user: User) {}
}

export class UserLogout implements Action {
    public readonly type = EnumUserActions.UserLogout;
}

export class SetUser implements Action {
    public readonly type = EnumUserActions.SetUser;
    constructor(public user: User) {}
}

export class GetAlert implements Action {
    public readonly type = EnumUserActions.GetAlert;
}

export class SetAlert implements Action {
    public readonly type = EnumUserActions.SetAlert;
    constructor(public alerts: Alert[]) {}
}

export class GetFavorite implements Action {
    public readonly type = EnumUserActions.GetFavorite;
}

export class SetFavorite implements Action {
    public readonly type = EnumUserActions.SetFavorite;
    constructor(public favorites: Favorite[]) {}
}

export class GetNotification implements Action {
    public readonly type = EnumUserActions.GetNotification;
}

export class SetNotification implements Action {
    public readonly type = EnumUserActions.SetNotification;
    constructor(public notifications: Notifications[]) {}
}

export class SetNotificationCounter implements Action {
    public readonly type = EnumUserActions.SetNotificationCounter;
    constructor(public notificationCounter: number) {}
}

export class GetSinalization implements Action {
    public readonly type = EnumUserActions.GetSinalization;
}

export class SetSinalization implements Action {
    public readonly type = EnumUserActions.SetSinalization;
    constructor(public sinalizations: Sinalization[]) {}
}

export class HideOnboarding implements Action {
    public readonly type = EnumUserActions.HideOnboarding;
    constructor(public hide: boolean) {}
}

export type UserActions = SetUser
                        | GetAlert
                        | SetAlert
                        | GetFavorite
                        | SetFavorite
                        | GetNotification
                        | SetNotification
                        | SetNotificationCounter
                        | GetSinalization
                        | SetSinalization
                        | HideOnboarding;
