import { Component } from '@angular/core';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { HideOnboarding } from '@store/actions/user.action';
import { environment} from 'src/environments/environment';
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {

  sliding = true;
  counter = 0;
  limit = 2;
  items = [
    {
      imgUrl: 'edp.emarket.novo.onboarding.mercados.img',
      alt: 'edp.emarket.novo.onboarding.mercados.logo',
      text1: 'edp.emarket.novo.onboarding.consulta',
      text2: 'edp.emarket.menu.mercados'
    },
    {
      imgUrl: 'edp.emarket.novo.onboarding.alertas.img',
      alt: 'edp.emarket.novo.onboarding.alertas.logo',
      text1: 'edp.emarket.novo.onboarding.parametrizar',
      text2: 'edp.emarket.menu.alertas'
    }
  ];

  constructor(
    private readonly store: Store<AppState>
  ) {

    if (environment.spain === false) {
      this.items.push({
        imgUrl: 'edp.emarket.novo.onboarding.sinalizacoes.img',
        alt: 'edp.emarket.novo.onboarding.sinalizacoes.logo',
        text1: 'edp.emarket.novo.onboarding.sinalizacoes.envio',
        text2: 'edp.emarket.menu.sinalizacoes'
      });
      this.limit = 3;
    }
  }

  nextSlide($event) {
    this.counter++;
    if (this.counter === this.limit) {
      setTimeout(() => {
        this.store.dispatch(new HideOnboarding(true));
      }, 500);
    }
  }

}
