import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as webFontLoader from 'webfontloader/webfontloader.js';

webFontLoader.load({
  google: {
    families: ['Roboto:100,300,400,400italic,500,700,900:latin'],
  },
  custom: {
    families: [
      'Font Awesome\ 5 Icons:400,900',
      'EDP Preon',
      'EDPMainUtilityIcons',
      'EDPSecondaryIconsBuildings',
      'EDPSecondaryIconsHealth',
      'EDPSecondaryIconsIndustryEnergy',
      'EDPSecondaryIconsNature',
      'EDPSecondaryIconsPower',
      'EDPSecondaryIconsScience',
      'EDPSecondaryIconsTransportation',
    ],
    urls: [
      '//use.fontawesome.com/releases/v5.0.8/css/all.css',
    ],
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err: Error) => console.error(err));
