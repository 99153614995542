<header>
    <div>
        <a [routerLink]="['/home']" class="order-2 pr-2" id="sidebar-link-home">
            <img [src]="'/assets/logo-horizontal-white-2.svg'" alt="EDP Logo" width="100" />
        </a>
    </div>
    <div [routerLink]="['/home']" class="dialog-close"><i class="icon-edponline-Bra-071 font-size-23 dialog-close-icon"></i></div>
</header>

<div class="container">
    <div class="row">
        <div class="col">
            <p>
                <b>
                    Es posible rechazar el uso de cookies en este sitio web y revocar los consentimientos utilizando la siguiente
                    herramienta
                </b>
            </p>
            <button class="btn bg ot-sdk-show-settings">Cookie Settings</button>
            <h2>1. Introducción</h2>
            <p>
                EDP Comercial - Comercialização de Energia S.A. actua como Responsable del Tratamiento de las Cookies que se instalen a
                través de la navegación por el website
                <a target="_blank" rel="noopener noreferrer" href="https://emarkets.edpenergia.es/">www.emarkets.edpenergia.es</a>
                (en adelante, "website").
            </p>
            <p>
                La presente Política de Cookies intenta explicar, de forma transparente y específica, cómo se utiliza la información
                personal recogida a través de la utilización de este website. Es con este espíritu con el que, a través de la presente
                Política de Cookies, se detalla el cómo, cuándo y porqué de la utilización de Cookies en este website.
            </p>
            <p>
                Este website utiliza Cookies, no sólo para garantizar que ofrecemos una experiencia única y personalizada, sino también para
                poder compartir con nuestros socios tecnológicos datos agregados que nos permitan mejorar, de forma continua e integrada,
                nuestra website y su experiencia de navegación.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>2. ¿Qué son las Cookies?</h2>
            <p>
                Las Cookies son ficheros digitales con pequeños fragmentos de información (y generalmente con un identificador único), que
                son descargados y almacenados en el dispositivo del visitante de un website. Pueden ser temporales (siendo borrados
                automáticamente cuando el browser se cierra) o persistentes (hasta una fecha de expiración definida), así como propias
                (definidas por la entidad que opera el site) o de responsabilidad de terceros.
            </p>
            <p>
                Las Cookies pueden clasificarse en relación a la finalidad perseguida. Existen Cookies que son estrictamente necesarias para
                el funcionamiento del website (“Cookies Técnicas”), mientras que otras se utilizan para saber dónde se encuentra el usuario
                cuando solicita un servicio ( “Cookies de Geolocalización”, o bien para proporcionar información estadística con la
                intención de analizar la utilización del website y el respectivo rendimiento (“Cookies analíticas”). Hay Cookies que son
                utilizadas para poner a disposición funcionalidades adicionales en el website o para guardar las preferencias del visitante
                en relación a la navegación en el mismo, siempre que utilice el mismo dispositivo (“Cookies de personalización”). Otras
                cookies permiten gestionar de forma más eficaz la publicidad mostrada durante la navegación, tanto propia como de terceros,
                adecuando el contenido al uso que realice de nuestra web (“Cookies de publicidad y perfilado”).
            </p>
            <p>
                El visitante podrá consultar dos plataformas que proporcionan información más detallada sobre Cookies y que no guardan
                ningún tipo de relación comercial o jurídica con EDP CLIENTES, S.A.U. y EDP SOLAR ESPAÑA:
                <a target="_blank" rel="noopener noreferrer" href="https://allaboutcookies.org/">www.allaboutcookies.org</a>
                o
                <a target="_blank" rel="noopener noreferrer" href="https://www.aboutcookies.org/">https://www.aboutcookies.org</a>
                .
            </p>
            <p>
                Adicionalmente, el usuario podrá saber más sobre cuáles son las Cookies que se utilizan es este website a partir de la lista
                que se encuentra disponible en el apartado 4 de esta política de cookies.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>3. ¿Por qué utilizar Cookies?</h2>
            <p>
                La utilización de Cookies es una práctica habitual en todos los websites, no perjudicando en forma alguna los dispositivos
                (ordenadores, tablets, teléfonos móviles, etc.) en que son almacenadas y permitiendo una mejor experiencia de utilización de
                las propias plataformas, tanto en términos de rendimiento, como en términos de navegación, toda vez que los contenidos
                disponibles estarán más orientados a las necesidades reales y expectativas de los usuarios.
            </p>
            <p>
                Las Cookies permiten, también, que el Website memorice informaciones sobre su visita, así como su idioma preferido, su
                localización, la recurrencia de sus sesiones y otras variables que consideramos como relevantes para hacer su experiencia de
                búsqueda mucho más confortable y eficiente.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>4. Cookies utilizadas y respectivas finalidades</h2>
            <p>
                EDP CLIENTES, S.A.U. y EDP SOLAR ESPAÑA detalla, a continuación, la información relativa a las Cookies utilizadas en el
                website, así como las finalidades de tratamiento de los datos que las justifican.
            </p>
            <p>
                Este website podrá contener links con otros websites o aplicaciones, incluyendo los que sean responsabilidad de otros
                responsables diferentes a los de este website. En caso de que el usuario acceda a uno de esos links o aplicaciones, debe
                tener en consideración que las respectivas Políticas de Cookies son de la exclusiva responsabilidad de las entidades que las
                generan. Por este motivo, el usuario deberá leer las Políticas de Cookies de otras plataformas o aplicaciones antes de
                utilizarlas.
            </p>
        </div>
    </div>
    <div id="ot-sdk-cookie-policy"></div>

    <div class="row">
        <div class="col">
            <h2>5. ¿Debo aceptar la utilización de Cookies?</h2>
            <p>
                En cumplimiento de la actual legislación en vigor, con excepción de las cookies estrictamente necesarias y de las cookies de
                desempeño/análisis, este website solo podrá recurrir a la utilización de Cookies mediante el consentimiento previo y expreso
                del visitante. EDP CLIENTES, S.A.U. y EDP SOLAR ESPAÑA se compromete a utilizar Cookies solamente para las finalidades
                referidas en el punto anterior.
            </p>
            <p>
                Es importante referir que la utilización de Cookies es esencial para el correcto funcionamiento del website en su totalidad,
                por lo que se recomienda su aceptación.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>6. Gestión de Cookies</h2>
            <p>
                La mayoría de los navegadores permite el control sobre las Cookies almacenadas en los dispositivos del visitante, así como
                su eliminación inmediata, en caso de que el usuario pretenda dejar de permitir el almacenamiento local de Cookies.
            </p>
            <p>
                Los usuarios pueden siempre configurar los dispositivos y los browsers que utilizan para aceptar todas o algunas Cookies,
                para notificar siempre que se emita una cookie, o para no recibir nunca Cookies. Para que el visitante pueda, de forma
                sencilla e intuitiva, gestionar sus Cookies a partir de su browser, podrá utilizar uno de los siguientes links:
            </p>
            <ul>
                <li>
                    Para más información sobre “Navegación Privada” y gestión de Cookies en el browser Firefox, haga clic
                    <a
                        href="https://support.mozilla.org/es/kb/navegacion-privada-Firefox-no-guardar-historial-navegacion"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        aqui
                    </a>
                    .
                </li>
                <li>
                    Para más información sobre “Navegación como Incógnito” y gestión de Cookies en el browser Chrome, haga clic
                    <a href="https://support.google.com/chrome/answer/95464?hl=es" target="_blank" rel="noopener noreferrer">aqui</a>
                    .
                </li>
                <li>
                    Para más información sobre “Navegación Privada” y gestión de Cookies a partir del browser Internet Explorer, haga clic
                    <a
                        href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        aqui
                    </a>
                    .
                </li>
                <li>
                    Para más información sobre “Navegación Privada” y gestión de Cookies a partir de Safari, haga clic
                    <a href="https://support.apple.com/es-es/guide/safari/ibrw1069/mac" target="_blank" rel="noopener noreferrer">aqui</a>
                    .
                </li>
                <li>
                    Para más información sobre “Navegación Privada” y gestión de Cookies en el browser Opera, haga clic
                    <a
                        href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        aqui
                    </a>
                    .
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>7. Transferencias internacionales de datos</h2>
            <h3>Cookies Propias</h3>
            <p>La información almacenada no será objeto de una transferencia internacional de datos.</p>
            <h3>Cookies de terceros</h3>
            <p>
                El usuario puede informarse de las transferencias a terceros países que, en su caso, realicen los terceros identificados en
                el apartado 4 de esta política de cookies, en sus correspondientes políticas.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>8. Derechos de los interesados</h2>
            <p>
                Se recomienda la consulta de nuestra Política de Privacidad para obtener más información acerca del tratamiento de datos que
                realizamos, así como la forma de ejercer los derechos y reclamaciones del usuario ante el Delegado de Protección de Datos de
                EDP CLIENTES, S.A.U. y EDP SOLAR ESPAÑA, S.A.U. y/o a la autoridad de control correspondiente.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>9. Modificación de la Política de Cookies</h2>
            <p>
                Esta política de cookies puede ser actualizada y modificada en cualquier momento por EDP CLIENTES, S.A.U. y EDP SOLAR
                ESPAÑA. Por ello, le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar
                adecuadamente informado sobre cómo y para qué usamos las cookies.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h2>10. Contacto</h2>
            <p>
                Para cualquier cuestión sobre la Política de Cookies de este website, el visitante podrá contactar con EDP CLIENTES, S.A.U.
                y EDP SOLAR ESPAÑA, S.A.U. a través de los canales indicados en la página
                <a href="https://www.edpenergia.es/es/atencion-cliente/" target="_blank" rel="noopener noreferrer">
                    www.edpenergia.es/es/atencion-cliente/
                </a>
                .
            </p>
        </div>
    </div>
</div>
