import { FormControl, FormGroup } from '@angular/forms';

export abstract class BaseComponent {
  protected formSubmit = false;
  public form: FormGroup;

  protected resetForm(): void {
    this.form.reset();
    this.formSubmit = false;
  }

  public addClassError(field) {
    return {
      error: this.hasError(field),
    };
  }

  public hasError(
    field: string,
    validators?: Array<string>,
    live?: boolean
  ): boolean {
    const oField: FormControl = this.form.get(field) as FormControl;
    let result = !oField.valid && (this.formSubmit || live);

    if (validators) {
      result = this.hasErrorValidator(oField, validators, live);
    }
    return result;
  }

  private hasErrorValidator(
    field: FormControl,
    validators: Array<string>,
    live?: boolean
  ): boolean {
    let result = false;

    if (field.errors) {
      validators.forEach((validator) => {
        if (
          field.errors.hasOwnProperty(validator) &&
          (this.formSubmit || live)
        ) {
          result = true;
        }
      });
    }
    return result;
  }

  public hasFormError(validators: Array<string>, live?: boolean): boolean {
    let hasError = false;

    validators.forEach((validator) => {
      if (this.form.hasError(validator)) {
        hasError = true;
      }
    });
    return hasError && (this.formSubmit || live);
  }

  public hasErrorInFields(
    fields: Array<string>,
    validators?: Array<string>,
    live?: boolean
  ): boolean {
    let hasError = false;

    fields.forEach((field) => {
      if (this.hasError(field, validators, live)) {
        hasError = true;
      }
    });
    return hasError && (this.formSubmit || live);
  }
}
