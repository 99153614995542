import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { SetUser } from '@store/actions/user.action';

@Injectable()
export class GlobalInterceptor {
    constructor(
        private store: Store<AppState>,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('HTTP Request: ', req);
        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // response here
                        // console.log(HttpResponse);
                    }
                },
                (err: any) => {
                    this.handleError(err);
                }),
            finalize(() => {
                // resolver after completion
            }),
        );
    }

    handleError(err: any) {
        if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
                this.store.dispatch(new SetUser(null));
            }
        } else if (err.error instanceof Error) {
            // your Generic error treatment here
            console.error('An error of type Error occurred:', err.error.message);
        } else {
            // your Default error treatment here
            console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
    }
}
