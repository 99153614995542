import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomTranslateLoader } from './translation-loader';
import { TranslationKeyValues } from './translation.model';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(
        private readonly httpClient: HttpClient
    ) {}

    getTranslation(language: string): Observable<TranslationKeyValues> {
        return this.httpClient.post<TranslationKeyValues>(
            `${environment.apiUrl}/markets/language`,
            {
                region: language,
            }
        );
    }
}

export function CustomTranslateLoaderFactory(
    translationService: TranslationService
): CustomTranslateLoader {
    return CustomTranslateLoader.of(translationService);
}
