<div class="collapse navbar-collapse order-3 bottomPart show side-bar d-none d-md-block">

    <div class="logo-wrapper pb-3">
      <a [routerLink]="['/home']" class="order-2 pr-2" id="sidebar-link-home">
        <img [src]="isNotSpain === false ? '/assets/logo-energia.png' : '/assets/logo-horizontal-white-2.svg'" alt="EDP Logo" width="100">
      </a>
    </div>

    <div class="user-wrapper d-flex align-items-center">
        <div class="foto-perfil margin-left-20-perc d-flex justify-content-between align-items-center">
            <span class="logged-off text-white d-flex justify-content-center align-items-center">
                <i class="icon-edponline-Bra-081 font-size-36"></i>
            </span>
        </div>
        <div class="d-flex flex-column pt-2 pl-2 user-info" *ngIf="isLoggedIn">
            <span class="user-name-text text-white">{{ user.firstname }} {{ user.lastname }}</span>
            <span class="user-email-text text-white">{{ user.email }}</span>
        </div>
    </div>

    <ul class="navbar-nav mt-4">
        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" routerLink="/home" id="nav-home">
            <i class="icon-edponline-Bra-018 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.home' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" routerLink="/markets" id="nav-markets">
            <i class="icon-edponline-Bra-023 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.mercados' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-favorites">
            <i class="icon-edponline-Bra-029 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.favoritos' | translate}}</span>
        </a>    

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" routerLink="/favorites" *ngIf="isLoggedIn" id="nav-favorites">
            <i class="icon-edponline-Bra-029 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.favoritos' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-manageAlerts">
            <i class="icon-edponline-Bra-149 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.alertas' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" routerLink="/manageAlerts" *ngIf="isLoggedIn" id="nav-manageAlerts">
            <i class="icon-edponline-Bra-149 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.alertas' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="nav-signalings">
            <i class="icon-edponline-Bra-094 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.sinalizacoes' | translate}}</span>
        </a>

        <a class="d-flex align-items-center rectangle pl-3" routerLinkActive="activeRect" routerLink="/signalings" *ngIf="isLoggedIn && isNotSpain" id="nav-signalings">
            <i class="icon-edponline-Bra-094 font-size-36"></i>
            <span class="menu-item-text ml-3">{{'edp.emarket.menu.sinalizacoes' | translate}}</span>
        </a>
    </ul>

    <div class="select-l pl-5">
      <app-select-language></app-select-language>
    </div>

    <div class="modal-footer-sidebar">
        <div class="separator-line"></div>
        <div class="height-42 d-flex align-items-end">
            <div class="w-100 pl-5">
                <i class="icon-edponline-Bop-137 login-icon" [ngClass]="isLoggedIn ? 'rotate-90' : 'rotate-270'"></i>
                <span class="menu-item-session ml-3" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="btn-login">{{'edp.emarket.menu.iniciar.sessao' | translate}}</span>
                <span class="menu-item-session ml-3" (click)="logout()" *ngIf="isLoggedIn" id="btn-logout">{{'edp.emarket.menu.terminar.sessao' | translate}}</span>
            </div>
        </div>
        <div class="height-42"></div>
    </div>
  </div>
