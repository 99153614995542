import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


export class GoogleAnalyticsData {
    constructor(
        public event: string,
        public pagepath: string,
        public pagetitle: string
    ) { }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
    private previousUrl: string;

    constructor(
        private readonly google: GoogleTagManagerService,
        private readonly activatedRoute: ActivatedRoute
    ) { }

    public pageChangeEvent(event: NavigationEnd): void {
        if (event.url !== this.previousUrl) {
            const getLastChild = (child: ActivatedRoute): ActivatedRoute => {
                return child.firstChild ? getLastChild(child.firstChild) : child;
            };
            const route: ActivatedRoute = getLastChild(this.activatedRoute);

            const googleAnalyticsData: GoogleAnalyticsData = new GoogleAnalyticsData(
                'pageview',
                event.url,
                route.snapshot.data.title
            );
            this.previousUrl = event.url;

            this.google.pushTag(googleAnalyticsData);
        }
    }
}
