import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EnumPopulateStateActions } from '@store/actions/populate.actions';
import { UserLogin, HideOnboarding } from '@store/actions/user.action';
import { SetMarket, SetMarketHome, SetMarketDetails, SetMarketFilter } from '@store/actions/market.action';
import { SetVariation, SetMaturity } from '@store/actions/config.action';

@Injectable()
export class PopulateEffects {
    populateState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnumPopulateStateActions.PopulateState),
            switchMap((storageCache: any) => {
                return [
                    new UserLogin(storageCache.payload.user.profile),
                    new SetMarket(storageCache.payload.markets.list),
                    new SetMarketHome(storageCache.payload.markets.home),
                    new SetMarketDetails(storageCache.payload.markets.details),
                    new SetMarketFilter(storageCache.payload.markets.filter),
                    new SetVariation(storageCache.payload.config.variation),
                    new SetMaturity(storageCache.payload.config.maturity)
                ];
            }),
            catchError(() => of({ type: '[Populate] Populate store when refresh page' }))
        )
    );

    constructor(private actions$: Actions) {}
}
