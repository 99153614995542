
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from '@models/message';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private message: BehaviorSubject<Message> = new BehaviorSubject(null);
    public message$ = this.message.asObservable();

    public show(message: string, type = 'success'): void {
        this.message.next({
            message,
            type
        } as Message);
    }

    public clear(): void {
        this.message.next(null);
    }
}
