import { User } from '@models/user.model';
import { Notifications } from '@models/notifications';
import { Favorite } from '@models/favorite';
import { Sinalization } from '@models/sinalization';
import { Alert } from '@models/alerts';

export interface UserState {
    profile: User;
    notification: Notifications[];
    favorite: Favorite[];
    sinalization: Sinalization[];
    notificationCounter: number;
    alert: Alert[];
    hideOnboarding: boolean;
}

export const initialUserState: UserState = {
    profile: null,
    notification: [],
    favorite: [],
    sinalization: [],
    notificationCounter: 0,
    alert: [],
    hideOnboarding: localStorage.getItem('emarkets-hideonboarding') === '1' || false
};

export function getInitialUserState(): UserState {
    return initialUserState;
}
