import { CallbackComponent } from '@edpc/edp-auth';
import { Component } from '@angular/core';

@Component({
    selector: 'app-callback',
    template: ``
})
export class AppCallbackComponent extends CallbackComponent {

}
