import { createSelector } from '@ngrx/store';
import { UserState } from '../state/user.state';
import { AppState } from '@store/state/app.state';
import { Alert } from '@models/alerts';

const selectUser = (state: AppState): UserState => state.user;

export const getLoggedUser = createSelector(
    selectUser,
    (state: UserState) => state.profile
);

export const getAlert = createSelector(
    selectUser,
    (state: UserState) => state.alert
);

export const getSinalization = createSelector(
    selectUser,
    (state: UserState) => state.sinalization
);

export const getNotification = createSelector(
    selectUser,
    (state: UserState) => state.notification
);

export const getNotificationCounter = createSelector(
    selectUser,
    (state: UserState) => state.notificationCounter
);

export const getNotificationAlerts = createSelector(
    getNotification,
    getAlert,
    (notifications, alerts) => {
        const alertList: Alert[] = [];
        if (notifications.length && alerts.length) {
            notifications.forEach(notification => {
                const alert = alerts.find(alertData => alertData.aid === notification.alert.aid);

                if (alert) {
                    const tmpAlert = {...alert};
                    tmpAlert.cd = notification.cd;

                    if (!tmpAlert.notifications) {
                        tmpAlert.notifications = [];
                    }
                    tmpAlert.notifications.push(notification);

                    alertList.push(tmpAlert);
                }
            });
        }

        return alertList;
    }
  );

export const getFavorite = createSelector(
    selectUser,
    (state: UserState) => state.favorite
);

export const hideOnboarding = createSelector(
    selectUser,
    (state: UserState) => state.hideOnboarding
);
