import { ActionReducer, Action } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { StorageMap } from '@ngx-pwa/local-storage';
import { take } from 'rxjs/operators';

export function storageMetaReducer<S, A extends Action = Action>(storage: StorageMap) {
    return (reducer: ActionReducer<S, A>) => {
        let onInit = true;
        return (state: S, action: A): S => {
            const nextState = reducer(state, action);
            if (onInit) {
                onInit = false;
                storage.get(environment.localStorageKey).pipe(take(1)).subscribe(storageState => {
                    return { ...nextState, storageState };
                });

            }
            storage.set(environment.localStorageKey, nextState).subscribe();
            return nextState;
        };
    };
}

