<section class="public-container d-flex flex-column justify-content-start">
  <div class="public-header">
    <div class="logo">
      <img alt="EDP" class="brand" src="/assets/logo-horizontal-white-2.svg">
    </div>
  </div>
  <form [formGroup]="form" (submit)="onSubmit()" autocomplete="off" class="flex-grow-1">
    <h1 class="edp-font-L1 text-center mb-5">{{'edp.emarket.login' | translate}}</h1>
    <div class="h-100">
      <div class="form-group">
        <label for="form-username"> {{'edp.emarket.login.usuario' | translate}}</label>
        <input type="text" class="form-control" [ngClass]="addClassError('username')" id="form-username" formControlName="username" />
        <span>{{'edp.emarket.login.doc.indentificativo' | translate}}</span>

        <span class="error form-error" *ngIf="hasError('username', ['not-found'])">
          {{'edp.emarket.login.usuario.errado' | translate}}
        </span>

      </div>
      <div class="form-group mt-4">
        <label for="form-password"> {{'edp.emarket.login.password' | translate}}</label>
        <input type="password" [ngClass]="addClassError('password')" class="form-control" id="form-password" formControlName="password" />
        <a class="edp-text-link edp-color-brown-grey" tabindex="3" href="https://www.edpenergia.es/actir/#forgetpassword​" rel="noopener" target="_blank" >
          {{'edp.emarket.login.esqueceu.password' | translate}}
        </a>
      </div>

      <button type="submit" [disabled]="form.invalid" class="btn bg acccder cursor-pointer w-100 mt-2 mb-3 mb-sm-4" id="btn-login">
        {{'edp.emarket.login.acessar' | translate}}
      </button>
    </div>
  </form>

  <div class="public-footer text-center py-3">
    <hr>
    <span>{{'edp.emarket.login.sem.conta' | translate}} <br>
      {{'edp.emarket.login.area.privada' | translate}}
    </span>
    <a href="https://www.edpenergia.es/actir/#register" rel="noopener" target="_blank" class="btn btn-link font-weight-bold">
      {{'edp.emarket.login.registrar' | translate}}
      <i class="icon-edponline-Bop-081"></i>
    </a>
  </div>
</section>
<app-message-box [center]="true"></app-message-box>