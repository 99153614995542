import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@components/base/base.component';
import { ApiLogin } from '@models/api-response';
import { UserAuthenticate } from '@models/user.model';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@services/authentication.service';
import { Loader } from '@store/actions/config.action';
import { AppState } from '@store/state/app.state';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {

  private readonly formSubmitSubscription = new Subject<boolean>();
  protected formSubmit = false;
  public form: FormGroup;
  public isMobile: boolean;
  private readonly rememberToken: string;
  private readonly cmdToken: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store<AppState>
  ) {

    super();

    const params = activatedRoute.snapshot.params;
    this.rememberToken = params.rememberToken ? params.rememberToken : null;
    this.store.dispatch(new Loader(false));
   }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

    this.form.setErrors({});

    if (this.rememberToken) {
      this.form.markAsDirty();
    }

    if (!environment.spain) {
      this.router.navigate(['home']);
    }
  }

  public onSubmit() {
    this.formSubmitSubscription.next(true);
    this.sendData();
  }

  ngOnDestroy() {
    this.formSubmitSubscription.unsubscribe();
  }

  public sendData() {
    const username = this.form.value.username ? this.form.value.username.replace(/\s/g, '') : '';

    this.form.controls.username.setValue(username);
    this.formSubmit = true;

    if (!this.form.invalid) {
      const password = this.form.value.password;
      const userAuth: UserAuthenticate = {
        username,
        password
      };
      this.authenticationService.validateUser(userAuth).subscribe((resp: ApiLogin) => {
        if (!resp.success) {
          this.form.controls.username.setErrors({ 'not-found': true });
        }
      });
    }
  }
}
