<div class="container">
    <div class="row">
        <div class="main" routerLink="/home" id="spash-home-link">
            <div class="d-flex justify-content-center pt-5 pr-3">
                <img src="/assets/logo-horizontal-white-2.svg" alt="EDP Logo" class="splash-edp-logo">
            </div>
            <div class="d-flex justify-content-center emarkets-text">
                <span class="emarkets-title tab-link">{{'edp.emarket.splashscreen' | translate}}</span>
            </div>
        </div>
    </div>
</div>
