<nav class="navbar d-flex w-100 justify-content-between align-items-end d-md-none">
    
    <div class="edp-logo-img">
        <img src="/assets/edp_new_logo.svg"
             routerLink="/home"
             class="pl-2"
             alt="EDP Logo"
             id="logo-img">
    </div>

    <div class="d-flex alerts-link">
        <div class="d-flex align-items-center alert-icon" routerLink="/manageAlerts/alerts" *ngIf="isLoggedIn" id="alert-link">
            <i class="icon-edponline-Bra-030 font-size-32"></i>
            <div class="notification-counter text-white font-size-11 d-flex align-items-center justify-content-center" *ngIf="notificationsCounter > 0">
                <span class="counter-text">{{ notificationsCounter }}</span>
            </div>
        </div>

        <div class="d-flex align-items-center" (click)="openDialogLogin()" *ngIf="!isLoggedIn" id="alert-link">
            <i class="icon-edponline-Bra-030 font-size-32"></i>
            <div class="notification-counter text-white font-size-11 d-flex align-items-center justify-content-center" *ngIf="notificationsCounter > 0">
                <span class="counter-text">{{ notificationsCounter }}</span>
            </div>
        </div>
    
        <i class="icon-edponline-Bra-077 font-size-32 pl-3 navbar-menu" (click)="openMenu()" id="open-menu"></i>
    </div>

</nav>
